export default class Faq {
    constructor() {
        if ($('#auth-toggle').length) {
            $('#auth-toggle').change(function () {
                if ($('#auth-toggle').is(':checked')) {
                    $('#auth-box').slideDown();
                }
                else {
                    $('#auth-box').slideUp();
                }
            });
            $('#auth-toggle').change(function () {
                $('#auth').val('');
            });
        }
        if ($('#faq-form').length) {
            $('#category').multiselect({
                buttonClass: 'form-control',
                buttonContainer: '<div class="btn-group bootstrap-multiselect" />',
                numberDisplayed: 1,
                nSelectedText: ' Kategorien',
                allSelectedText: 'Alle Kategorien',
                nonSelectedText: 'bitte wählen'
            });
        }
        if ($('#faq-list').length) {
            $.fn.animateRotate = function (start, angle, duration, easing, complete) {
                var args = $.speed(duration, easing, complete);
                var step = args.step;
                return this.each(function (i, e) {
                    args.complete = $.proxy(args.complete, e);
                    args.step = function (now) {
                        $.style(e, 'transform', 'rotate(' + now + 'deg)');
                        if (step) return step.apply(e, arguments);
                    };

                    $({deg: start}).animate({deg: angle}, args);
                });
            };

            $('#faq-list h2').click(function () {
                if ($(this).hasClass('faq-closed')) {
                    $(this).find('i').animateRotate(0, 90);
                    $(this).next().slideDown();
                    $(this).attr({'class': 'faq-open'});
                }
                else {
                    $(this).find('i').animateRotate(90, 0);
                    $(this).next().slideUp();
                    $(this).attr({'class': 'faq-closed'});
                }
            });
        }
    }
}