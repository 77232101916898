import React from "react";
import SearchTable from './SearchTable'

export default class SearchTableAdminUser extends SearchTable {
    params = {
        sort_field: 'created',
        sort_order: 'desc',
        page: 1
    };
    apiUrl = '/api/admin/users';
    formId = 'admin-user-form';
    varPrefix = 'searchTableAdminUser';
    loadParamsRegExp = [
        /\/admin\/user\/(.*)/g
    ];

    sortDef = [
        { key: 'created', name: 'Registrierungsdatum' },
        { key: 'last_name', name: 'Name' }
    ];

    colDef = [
        { sortField: 'last_name', text: 'Name & E-Mail' },
        { sortField: 'created', text: 'Erstellt' },
        { text: 'Konto' },
        { text: 'Status'},
        { text: 'Aktionen' }
    ];

    renderTableRow(row) {
        return (
            <tr key={`user-${row.id}`}>
                {this.renderTableCellName(row)}
                {this.renderTableCellCreated(row)}
                {this.renderTableCellAccounts(row)}
                {this.renderTableCellStatus(row)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }

    renderTableCellName(row) {
        return(
            <td>
                {row.first_name} {row.last_name}<br/>
                <small> {row.email}</small>
            </td>
        )
    }

    renderTableCellCreated(row) {
        return(
            <td>{row.created.substr(8, 2)}.{row.created.substr(5, 2)}.{row.created.substr(0, 4)}</td>
        )
    }

    renderTableCellAccounts(row) {
        let iban_list = [];
        for (let i = 0; i < row.accounts.length; i++) {
            if (i !== 0)
                iban_list.push(<br key={`br-${i}`}/>);
            iban_list.push(
                <a href={`/admin/account/${row.accounts[i].id}/show`} key={`account-${row.accounts[i].id}`}>
                    {this.formatAccountType(row.accounts[i].type)} {this.formatAccountIdentifier(row.accounts[i].type, row.accounts[i].identifier)}
                </a>
            );
        }
        return(
            <td>{iban_list}</td>
        )
    }

    renderTableCellStatus(row) {
        return(
            <td>
                {this.formatUserStatus(row.status)}
            </td>
        )
    }

    renderTableCellActions(row) {
        return (
            <td>
                {this.renderTableCellActionIcon(`/admin/user/${row.id}/show`, 'info', 'Details', true)}
                {this.renderTableCellActionIcon(`/user/${row.id}/switch`, 'arrow-circle-o-right', 'In Nutzeransicht wechseln', true)}
            </td>
        )
    }
}