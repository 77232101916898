import React from "react";
import moment from 'moment';
import SearchTable from './SearchTable'
import { daterangepicker_ranges, daterangepicker_locale} from "../Constants";
import {formatDaterange, formatDuration, formatEnergy, formatPrice} from "../Format";

export default class SearchTableCharge extends SearchTable {
    params = {
        sort_field: 'auth_datetime',
        sort_order: 'desc',
        daterange: moment().subtract(1, 'month').format('DD.MM.YYYY') + ' - ' + moment().format('DD.MM.YYYY'),
        page: 1
    };
    apiUrl = '/api/charges';
    formId = 'charge-search-form';
    varPrefix = 'searchTableCharge';
    loadParamsRegExp = [
        /\/charge\/(.*)/g
    ];

    sortDef = [
        { key: 'auth_datetime', name: 'Datum & Uhrzeit' },
        { key: 'value_total', name: 'Betrag' }
    ];

    colDef = [
        { sortField: 'created', text: 'Datum & Uhrzeit' },
        { text: 'Ort' },
        { text: 'Status' },
        { sortField: 'value_total', text: 'Betrag'},
        { text: 'Aktionen' }
    ];

    init() {
        super.init();
        this.params.transaction_type = $('#transaction_type').val();
        $('#daterange').daterangepicker({
            startDate: moment().subtract(1, 'month'),
            endDate: moment(),
            ranges: daterangepicker_ranges,
            locale: daterangepicker_locale
        }, (start, end, label) => this.daterangepickerSubmit(start, end, label));
    }

    renderTableRow(row) {
        return (
            <tr key={`charge-${row.id}`}>
                {this.renderTableCellAuthDatetimeBeginEnd(row)}
                {this.renderTableCellLocation(row)}
                {this.renderTableCellStatus(row)}
                {this.renderTableCellValue(row)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }


    renderTableCellAuthDatetimeBeginEnd(row) {
        return(
            <td>
                {formatDaterange((row.status === 'auth') ? row.auth_datetime : row.begin, row.end)}<br/>
                <small>{(row.duration_raw) ? formatDuration(row.duration_raw) : ''}</small>
            </td>
        )
    }

    renderTableCellLocation(row) {
        return(
            <td>
                {row.unit_group.address},<br/>
                {row.unit_group.postalcode} {row.unit_group.locality}
            </td>
        )
    }

    renderTableCellStatus(row) {
        return(
            <td>
                {this.formatChargeStatus(row.status)}<br/>
                <small>{(row.energy_raw !== undefined) ? formatEnergy(row.energy_raw) : ''}</small>
            </td>
        )
    }

    renderTableCellValue(row) {
        return(
            <td>
                {(row.status === 'complete') ? formatPrice(row.value_total) : ''}
            </td>
        )
    }

    renderTableCellActions(row) {
        return(
            <td>
                {this.renderActionLink(`/charge/${row.id}/show`, 'info', true)}<br/>
                {}
            </td>
        )
    }

    formatChargeStatus(key) {
        if (key === 'auth') {
            return 'autorisiert'
        }
        if (key === 'incomplete') {
            return 'unvollständig';
        }
        if (key === 'complete') {
            return 'vollständig';
        }
        if (key === 'timeout') {
            return 'fehlgeschlagen';
        }
        return key;
    }
}