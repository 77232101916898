import '../sass/base.scss'
import '../sass/webapp.scss'

import React from "react";
import ReactDOM from "react-dom";

import Common from './Common';
import Faq from './Helper/Faq';
import AccountManagement from "./Helper/AccountManagement";
import UnitGroupMap from './Helper/UnitGroupMap';
import UnitCharge from './Helper/UnitCharge';
import Register from "./Helper/Register";
import UnitChargeStop from "./Helper/UnitChargeStop";

import SearchTableAdminUser from './SearchTable/SearchTableAdminUser';
import SearchTableAdminAccount from './SearchTable/SearchTableAdminAccount';
import SearchTableInvoice from './SearchTable/SearchTableInvoice';
import SearchTableOrder from './SearchTable/SearchTableOrder';
import SearchTableExternal from './SearchTable/SearchTableExternal';
import SearchTableCharge from './SearchTable/SearchTableCharge';
import SearchTableInvoiceTransaction from "./SearchTable/SearchTableInvoiceTransaction";


$(document).ready(function () {
    window.common = new Common();
    if ($('body.faq').length) {
        window.faq = new Faq();
    }
    if ($('#account-validate-form').length) {
        window.account_management = new AccountManagement();
    }
    if ($('#unit-group-map-container').length) {
        window.unit_group_map = new UnitGroupMap();
    }
    if ($('.register-form, #account-form').length) {
        window.register = new Register();
    }
    
    let reactObjects = {
        'unit-charge': UnitCharge,
        'unit-charge-stop': UnitChargeStop,
        'charge-search-results': SearchTableCharge,
        'order-search-results': SearchTableOrder,
        'external-search-results': SearchTableExternal,
        'invoice-search-results': SearchTableInvoice,
        'invoice-transaction-search-results': SearchTableInvoiceTransaction,
        'admin-user-results': SearchTableAdminUser,
        'admin-account-results': SearchTableAdminAccount,

    };

    for (const [html_id, ReactClass] of Object.entries(reactObjects)) {
        if (document.getElementById(html_id)) {
            ReactDOM.render(
                <ReactClass ref={(reactClass) => {window[ReactClass.name.charAt(0).toLowerCase() + ReactClass.name.slice(1)] = reactClass}} />,
                document.getElementById(html_id)
            );
        }
    }
});
