import React from "react";
import SearchTable from './SearchTable'
import moment from 'moment';
import { daterangepicker_ranges, daterangepicker_locale } from "../Constants";
import {formatPrice} from "../Format";

export default class SearchTableOrder extends SearchTable {
    params = {
        sort_field: 'auth_datetime',
        sort_order: 'desc',
        daterange: moment().subtract(1, 'month').format('DD.MM.YYYY') + ' - ' + moment().format('DD.MM.YYYY'),
        page: 1
    };
    apiUrl = '/api/orders';
    formId = 'external-search-form';
    varPrefix = 'searchTableExternal';
    loadParamsRegExp = [
        /\/external\/(.*)/g
    ];

    sortDef = [
        { key: 'auth_datetime', name: 'Datum' },
        { key: 'value_total', name: 'Betrag' }
    ];

    colDef = [
        { sortField: 'auth_datetime', text: 'Datum' },
        { text: 'Verkäufer' },
        { text: 'Produkte' },
        { sortField: 'value_total', text: 'Betrag'},
        { text: 'Aktionen' }
    ];

    init() {
        super.init();
        this.params.transaction_type = $('#transaction_type').val();
        $('#daterange').daterangepicker({
            startDate: moment().subtract(1, 'month'),
            endDate: moment(),
            ranges: daterangepicker_ranges,
            locale: daterangepicker_locale
        }, (start, end, label) => this.daterangepickerSubmit(start, end, label));
    }

    renderTableRow(row) {
        return (
            <tr key={`order-${row.id}`}>
                {this.renderTableCellDateTime(row)}
                {this.renderTableCellOperator(row)}
                {this.renderTableCellProducts(row)}
                {this.renderTableCellValue(row)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }
    renderTableCellOperator(row) {
        return(
            <td>
                {row.operator.name}
            </td>
        )
    }

    renderTableCellDateTime(row) {
        return(
            <td>
                {this.formatDate(row.auth_datetime, true)}
            </td>
        )
    }

    renderTableCellProducts(row) {
        if (row.status !== 'complete')
            return (<td>-</td>);
        let products = [];
        for (let i = 0; i < row.product.length; i++) {
            products.push(String(row.product[i].amount) + 'x ' + row.product[i].name);
        }
        return(
            <td>
                {products.join(', ')}
            </td>
        )
    }

    renderTableCellValue(row) {
        if (row.status !== 'complete')
            return (<td></td>);
        return(
            <td>
                {formatPrice(row.value_total)}
            </td>
        )
    }

    renderTableCellActions(row) {
        return(
            <td>
                {this.renderActionLink(`/external/${row.id}/show`, 'info', true)}
            </td>
        )
    }

}