import React from "react";
import {Decimal} from 'decimal.js';
import {formatPrice} from "../Format";

const { Component } = React;

export default class UnitChargeAccount extends Component {
    params = {};
    state = {
        appStatus: 'booting'
    };

    componentDidMount() {
        this.params.csrf_token = config_unit.csrfToken;
        this.params.unit = config_unit.uid;
        if (this.props.authData) {
            this.setState({
                appStatus: 'selected',
                chargeStatus: 'auth',
                accountSelected: true,
                auth: this.props.authData,
                accountDescr: this.props.accountDescr
            });
            return;
        }
        let params = Object.assign({}, this.params);
        $.post('/api/unit/charge/account', params)
            .then(data => {
                if (data.data.type === 'AccountSelection') {
                    this.setState({
                        appStatus: 'choosing',
                        accounts: data.data.accounts,
                        account: data.data.accounts[0].id
                    })
                }
                if (data.data.type === 'LoginRequired') {
                    this.setState({
                        appStatus: 'login'
                    })
                }
            });
    }

    loginFormSubmit(evt) {
        evt.preventDefault();
        if (['login', 'login-error'].includes(this.state.appStatus)) {
            let params = Object.assign({}, this.params, {
                uid: this.props.unit.uid,
                email: document.getElementById('email').value,
                password: document.getElementById('password').value
            });
            $.post('/api/unit/charge/login', params)
                .then(data => {
                    if (data.status)
                        return;
                    if (data.data.status === 'success' && data.data.accounts.length)
                        this.setState({
                            appStatus: 'choosing',
                            accounts: data.data.accounts,
                            account: data.data.accounts[0].id
                        });
                    else
                        this.setState({
                            appStatus: 'login-error'
                        })
                });
        }
    }

    updateSelectedAccount(evt) {
        evt.preventDefault();
        this.setState({
            account: parseInt(evt.target.value)
        });
    }

    selectSingleAccount(evt) {
        evt.preventDefault();
        let params = Object.assign({}, this.params, {
            csrf_token: this.params.csrf_token,
            account: this.state.account,
            unit: this.props.unit.uid,
            ruid: this.props.requestUid,
            backend: this.props.unit.tech_backend
        });
        $.post('/api/unit/charge/account/auth', params)
            .then(data => {
                if (data.status) {
                    this.setState({
                        appStatus: 'generic-fail'
                    });
                    return;
                }
                let accountDescr = '';
                for (let i = 0; i < this.state.accounts.length; i++) {
                    if (this.state.account === this.state.accounts[i].id) {
                        accountDescr = this.state.accounts[i].name + ' (' + this.state.accounts[i].iban + ' / ' + this.state.accounts[i].bic + ')';
                        break;
                    }
                }
                this.props.setAuthResult(data.data.auth, accountDescr);
                this.setState({
                    appStatus: 'selected',
                    chargeStatus: 'auth',
                    accountSelected: true,
                    accountDescr: accountDescr,
                    auth: data.data.auth
                })
            });
    }


    render() {
        return (
            <div>
                {this.renderAccoutLogin()}
                {this.renderSelectAccount()}
                {this.renderChargeAuthAccountData()}
            </div>
        )
    }

    renderAccoutLogin() {
        if (!['login', 'login-error'].includes(this.state.appStatus))
            return;
        return(
            <form onSubmit={this.loginFormSubmit.bind(this)}>
                <div className="row row-form">
                    <div className="col-md-12">
                        <p><i className="fa fa-credit-card" aria-hidden="true"></i> Lastschrift</p>
                        <p>
                            Um mit einem registrierten Bank-Account zu laden, müssen Sie sich einloggen. Haben Sie
                            noch keinen Account, können Sie sich <a href="/register">registrieren</a>.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className={`col-md-4${(this.state.appStatus === 'login-error') ? ' error' : ''}`}>
                        <label htmlFor="email">E-Mail</label>
                        <input type="text" name="email" id="email" className="form-control" />
                    </div>
                    <div className={`col-md-4${(this.state.appStatus === 'login-error') ? ' error' : ''}`}>
                        <label htmlFor="password">Passwort</label>
                        <input type="password" name="password" id="password" className="form-control" />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="password">&nbsp;</label>
                        <input type="submit" name="password" id="password" className="form-control" value="login" />
                    </div>
                </div>
            </form>
        );
    }

    renderSelectAccount() {
        if (this.state.appStatus !== 'choosing')
            return;
        return(
            <div className="row">
                <div className="col-md-12">
                    <p><i className="fa fa-credit-card" aria-hidden="true"></i> Lastschrift</p>
                    <p>Bitte wählen Sie Ihr Konto aus:</p>
                </div>
                <div className="col-md-8">
                    <select className="form-control" onChange={this.updateSelectedAccount.bind(this)}>
                        {this.state.accounts.map(account => {
                            return (
                                <option value={account.id} key={`account-${account.id}`}>
                                    {account.name} ({account.iban} / {account.bic})
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className="col-md-4">
                    <button className="btn btn-block btn-primary" onClick={this.selectSingleAccount.bind(this)}>
                        wählen
                    </button>
                </div>
            </div>
        )
    }

    renderChargeAuthAccountData() {
        if (this.state.appStatus !== 'selected')
            return;
        return(
            <div className="row">
                <div className="col-md-12">
                    <p><i className="fa fa-credit-card" aria-hidden="true"></i> Lastschrift via { this.state.accountDescr }</p>
                    {this.renderPrice()}
                    <p>
                        <i className="fa fa-check" aria-hidden="true"></i>
                        {' '}SEPA-Mandadats-Referenz {this.state.auth.uid}
                    </p>
                </div>
            </div>
        )
    }

    renderPrice() {
        return(
            <p>
                <i className="fa fa-eur" aria-hidden="true"></i>
                {' '}{formatPrice(this.state.auth.feeBase)} / Start,
                {' '}{formatPrice(this.state.auth.feeEnergy)} / kWh,
                {' '}{formatPrice(this.state.auth.feeTime)} / Stunde
            </p>
        )
    }
}