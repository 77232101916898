import React from "react";
import {formatPrice} from '../Format';
const { Component } = React;

export default class UnitChargeCharging extends Component {
    params = {};
    state = {
        appStatus: 'booting'
    };

    componentDidMount() {
        this.params.csrf_token = config_unit.csrfToken;
        this.params.unit = config_unit.uid;
        if (this.props.authData)
            return this.setState({
                auth: this.props.authData,
                appStatus: 'successfull'
            });
        this.props.socket.on('betterpayment-postback', (data) => {
            console.log(data);
            if (data.data.status === 'auth') {
                this.props.setAuthResult(
                    data.data.auth,
                    data.data.auth.creditcardType + ': **** **** **** ' + data.data.auth.creditcardNumber
                );
                this.setState({
                    appStatus: 'successfull',
                    auth: data.data.auth
                })
            }
            else {
                this.setState({
                    appStatus: 'failed'
                });
            }

        });

        let params = Object.assign({}, this.params, {requestUid: this.props.requestUid});
        $.post('/api/unit/charge/creditcard', params)
            .then(data => {
                this.setState({
                    appStatus: 'initialized',
                    betterpayment: data.data
                });
            });
    }


    render() {
        return(
            <div>
                {this.renderSelectBetterpayment()}
                {this.renderChargeAuthBetterpaymentData()}
                {this.renderChargeAuthBetterpaymentFailed()}
            </div>
        )
    }

    renderSelectBetterpayment() {
        if (this.state.appStatus !== 'initialized')
            return;
        return(
            <div className="row">
                <div className="col-md-12">
                    <p><i className="fa fa-credit-card" aria-hidden="true"></i> Kreditkarte</p>
                    <p>Bitte geben Sie zur Kreditkartenzahlung Ihre Daten an:</p>
                    <iframe className="betterpayment-iframe" src={this.state.betterpayment.action_data.url}/>
                </div>
            </div>
        )
    }

    renderChargeAuthBetterpaymentFailed() {
        if (this.state.appStatus !== 'failed')
            return;
        return(
            <div className="row">
                <div className="col-md-12">
                    <p><i className="fa fa-credit-card" aria-hidden="true"></i> Kreditkarte</p>
                    <p>
                        Zahlung fehlgeschlagen. Es wurde nichts von Ihrer Kreditkare abgebucht.
                        <a href="/map">Bitte versuchen Sie es noch einmal.</a>
                    </p>
                </div>
            </div>
        )
    }

    renderChargeAuthBetterpaymentData() {
        if (this.state.appStatus !== 'successfull')
            return;
        return(
            <div className="row">
                <div className="col-md-12">
                    <p>
                        <i className="fa fa-credit-card" aria-hidden="true"></i> Kreditkartenzahlung via {' '}
                        {this.state.auth.creditcardType} **** **** **** {this.state.auth.creditcardNumber}
                    </p>
                    {this.renderPrice()}
                </div>
            </div>
        )
    }

    renderPrice() {
        return(
            <p>
                <i className="fa fa-eur" aria-hidden="true"></i>
                {' '}{formatPrice(this.state.auth.feeBase)} / Start,
                {' '}{formatPrice(this.state.auth.feeEnergy)} / kWh,
                {' '}{formatPrice(this.state.auth.feeTime)} / Stunde
            </p>
        )
    }
}