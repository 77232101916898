import React from "react";
import io from 'socket.io-client';
const { Component } = React;
import moment from 'moment';
import { formatDurationByTimestamp, formatEnergy } from "../Format";
import { getURLParams } from "../Common";

import UnitChargeFree from "./UnitChargeFree";
import UnitChargeAccount from "./UnitChargeAccount";
import UnitChargeCreditCard from "./UnitChargeCreditCard";
import UnitChargeCharging from "./UnitChargeCharging";


export default class UnitCharge extends Component {
    params = {};
    existingSession = null;
    state = {
        resumed: false,
        appStatus: 'booting',
        transactionStatus: null,
        unitStatus: null,
        authType: null,
        authStatus: null,
        authData: null,
        meterHistory: {0: 0}
    };

    componentDidMount() {
        this.init();
        this.getUnitData();
    }

    init() {
        this.params.csrf_token = config_unit.csrfToken;
        this.urlParams = getURLParams();
        this.unitDataUrl = config_unit.opendataUrl + '/api/unit/' + config_unit.uid;
    }

    initSocket(unit_uid, tech_backend) {
        this.existingSession = JSON.parse(localStorage.getItem('unit-charge'));
        this.socket = io(config_unit.socketUrl + '/unit/charge');
        this.socket.on('connect', () => {
            if (this.existingSession) {
                this.socket.emit('resume', {
                    unit: config_unit.uid,
                    uid: this.existingSession.uid,
                    session: this.existingSession.session,
                    target: tech_backend
                });
            } else {
                this.socket.emit('init', {
                    unit: config_unit.uid,
                    target: tech_backend
                });
            }
        });
        this.socket.on('init-data', (data) => {
            if (localStorage.getItem('unit-charge')) {
                localStorage.removeItem('unit-charge');
            }
            this.setState({
                requestUid: data.requestUid,
                appStatus: (this.state.authType === 'free') ? 'authorizing' : 'initialized'
            });
        });
        this.socket.on('resume-data', (data) => {
            console.log(data);
            let token_to_auth = {
                'iban': 'account',
                'betterpayment_web': 'creditcard',
                'user': 'free'
            };
            let authData = data.auth;
            authData.session = this.existingSession.session;
            this.setState({
                requestUid: data.requestUid,
                accountDescr: data.accountDescr,
                appStatus: 'charging',
                authData: authData,
                unitMeterStart: data.meterStart,
                unitChargeStart: data.chargeStart,
                authType: token_to_auth[data.tokenType],
                meterHistory: data.meterHistory,
                resumed: true
            });
        });

        this.socket.on('unit-update', (data) => {
            console.log(data);
            if (data.type === 'MeterUpdate') {
                this.updateMeterUpdate(data.data);
            }
            if (data.type === 'StatusNotification') {
                this.updateStatusNotification(data.data);
            }
            if (data.type === 'StartTransaction') {
                this.updateStartTransaction(data.data, data.transaction);
            }
            if (data.type === 'StopTransaction') {
                this.updateStopTransaction(data.data, data.transaction);
            }
            if (data.type === 'TransactionTimeout') {
                this.updateTransactionTimeout(data.data, data.transaction);
            }
        });
    }


    updateMeterUpdate(data) {
        if (!this.state.unitMeterStart || !this.state.unitChargeStart)
            return;
        let meterHistory = this.state.meterHistory;
        let duration = parseInt(moment.duration(moment(data.data.datetime).diff(this.state.unitChargeStart)).as('seconds'));
        meterHistory[duration] = data.data.value - this.state.unitMeterStart;
        this.setState({
            meterHistory: meterHistory
        });
    }

    updateStatusNotification(data) {
        if (!data.status)
            return;
        this.setState({
            unitStatus: data.status
        });
    }

    updateStartTransaction(data, transaction) {
        if (data.status === 'error')
            return this.setState({
                appStatus: 'generic-fail'
            });
        if (transaction.uid !== this.state.authData.uid) {
            return;
        }
        this.setState({
            appStatus: 'charging',
            unitChargeStart: data.timestamp,
            unitMeterStart: data.meterStart
        });
    }

    updateStopTransaction(data, transaction) {
        if (data.status === 'error')
            this.setState({
                appStatus: 'generic-fail'
            });
        if (!this.state.authData || transaction.uid !== this.state.authData.uid) {
            return;
        }
        this.setState({
            appStatus: 'completed',
            unitChargeStop: data.timestamp,
            unitMeterStop: data.meterStop
        });
    }

    updateTransactionTimeout(data, transaction) {
        if (data.status === 'error')
            this.setState({
                appStatus: 'generic-fail'
            });
        if (transaction.uid !== this.state.authData.uid) {
            return;
        }
        if (localStorage.getItem('unit-charge')) {
            localStorage.removeItem('unit-charge');
        }
        this.setState({
            appStatus: 'timeout'
        });
    }

    setAuthType(auth_type, evt) {
        if (evt)
            evt.preventDefault();
        this.setState({
            authType: auth_type,
            appStatus: 'authorizing'
        });
    }

    setAuthResult(auth_data, auth_description) {
        this.setState({
            authData: auth_data,
            authDescription: auth_description,
            appStatus: 'authorized',
            transactionStatus: 'auth'
        });
    }

    getUnitData() {
        $.get(this.unitDataUrl)
            .then(data => {
                if (data.status) {
                    this.setState({
                        appStatus: 'invalid-unit'
                    });
                    return;
                }
                if (!data.data.remote_start) {
                    this.setState({
                        appStatus: 'invalid-unit'
                    });
                    return;
                }
                let update = {
                    unitGroup: data.data['unit-group'],
                    unit: data.data
                };
                if (!(parseFloat(data.data.fee_base) || parseFloat(data.data.fee_energy) || parseFloat(data.data.fee_time))) {
                    update.authType = 'free';
                }
                this.setState(update);
                this.initSocket(data.data.uid, data.data.tech_backend);
            });
    }

    chargeStart(evt) {
        evt.preventDefault();
        let params = {
            csrf_token: this.params.csrf_token,
            uid: this.state.authData.uid,
            session: this.state.authData.session
            //meter_details: document.getElementById('meter-details').checked
        };
        this.existingSession = {
            uid: this.state.authData.uid,
            session: this.state.authData.session
        };
        localStorage.setItem('unit-charge', JSON.stringify(this.existingSession));
        this.setState({
            appStatus: 'starting',
            transactionStatus: 'incompleteAvailable'
        });
        $.post('/api/unit/charge/start', params)
            .then(data => {
                if (data.status) {
                    this.setState({
                        appStatus: 'generic-fail'
                    });
                }
                this.setState({
                    appStatus: 'starting'
                });
            });

    }

    chargeStop(evt) {
        evt.preventDefault();
        this.setState({
            appStatus: 'stopping'
        });
        let params = {
            csrf_token: this.params.csrf_token,
            uid: this.state.authData.uid,
            session: this.state.authData.session,
            target: this.state.unit.tech_backend
        };
        $.post('/api/unit/charge/stop', params)
            .then(data => {
                localStorage.removeItem('unit-charge');
                if (this.state.unit.tech_backend === 'tcc' && !data.status) {
                    this.setState({
                        appStatus: 'completed'
                    });
                }
            });
    }

    render() {
        if (this.state.appStatus === 'booting') {
            return (
                <div className="unit-charge-loading">
                    ... wird geladen ...
                </div>
            );
        }

        if (this.state.appStatus === 'invalid-unit') {
            return (
                <div>
                    <h1>Jetzt laden</h1>
                    <p>
                        Leider kennen wir diesen Ladepunkt nicht. Vielleicht ist es einfacher, den Ladepunkt{' '}
                        <a href="/map">via Karte auszuwählen</a>?
                    </p>
                </div>
            );
        }
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <h1>Jetzt laden</h1>
                        {this.state.unit.remote_start_message &&
                            <p>{this.state.unit.remote_start_message}</p>
                        }
                        <p>
                            <i className="fa fa-plug" aria-hidden="true"></i>{' '}
                            {this.state.unit.uid}, {this.state.unitGroup.address},
                            {this.state.unitGroup.postcode} {this.state.unitGroup.locality}
                            {this.state.unitStatus !== null &&
                            <span>:{' '}
                                {this.state.unitStatus === 'Available' &&
                                    <span style={{color: '#009900'}}>frei</span>
                                }
                                {this.state.unitStatus === 'Preparing' &&
                                    <span style={{color: '#009900'}}>startbereit</span>
                                }
                                {!['Available', 'Preparing'].includes(this.state.unitStatus) &&
                                    <span style={{color: '#990000'}}>belegt</span>
                                }
                                </span>
                            }
                            .
                        </p>
                    </div>
                </div>
                {this.renderSelectPayment()}
                {this.state.authType === 'account' &&
                <UnitChargeAccount
                    accountDescr={this.state.accountDescr}
                    unit={this.state.unit}
                    authData={this.state.authData}
                    setAuthResult={this.setAuthResult.bind(this)}
                    requestUid={this.state.requestUid}
                />
                }
                {this.state.authType === 'creditcard' &&
                <UnitChargeCreditCard
                    unit={this.state.unit}
                    authData={this.state.authData}
                    setAuthResult={this.setAuthResult.bind(this)}
                    requestUid={this.state.requestUid}
                    socket={this.socket}
                />
                }
                {this.state.authType === 'free' &&
                <UnitChargeFree
                    unit={this.state.unit}
                    authData={this.state.authData}
                    setAuthResult={this.setAuthResult.bind(this)}
                    requestUid={this.state.requestUid}
                    socket={this.socket}
                />
                }
                {this.renderChargeStart()}
                {this.renderChargeStarting()}
                {this.renderChargeCharging()}
                {this.renderChargeStopping()}
                {this.renderChargeComplete()}
                {this.renderChargeTimeout()}

                {(this.state.appStatus === 'charging' && this.state.unit.tech_backend === 'tcc') &&
                    <UnitChargeCharging
                        unit={this.state.unit}
                        authData={this.state.authData}
                        requestUid={this.state.requestUid}
                        unitMeterStart={this.state.unitMeterStart}
                        unitChargeStart={this.state.unitChargeStart}
                        meterHistory={this.state.meterHistory}
                    />
                }
            </div>
        )
    }

    renderGenericFail() {
        if (this.state.appStatus !== 'generic-fail')
            return;
        return (
            <div className="row">
                <div className="col-md-12">
                    <p className="alert alert-danger">
                        Ein kritischer Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal.
                    </p>
                </div>
            </div>
        );
    }

    renderSelectPayment() {
        if (this.state.appStatus !== 'initialized')
            return;
        return (
            <div className="row">
                <div className="col-md-12">
                    <p>Wie wollen Sie den Ladevorgang bezahlen?</p>
                </div>

                <div className={`col-md-${(this.state.unit.remote_start_creditcard) ? '6' : '12'}`}>
                    <button className="btn btn-block btn-primary" onClick={this.setAuthType.bind(this, 'account', null)}>
                        Lastschrift
                    </button>
                </div>
                {this.state.unit.remote_start_creditcard &&
                    <div className="col-md-6">
                        <button className="btn btn-block btn-primary" onClick={this.setAuthType.bind(this, 'creditcard', null)}>
                            Kreditkarte
                        </button>
                    </div>
                }
            </div>
        );
    }

    renderChargeStart() {
        if (this.state.appStatus !== 'authorized')
            return;
        if (!this.state.unitStatus) {
            return (
                <div className="row">
                    <div className="col-md-12">
                        <p>
                            <i className="fa fa-spinner fa-spin fa-fw"></i>{' '}
                            Die Ladesäule hat noch nicht reagiert. Bitte warten ...
                        </p>
                    </div>
                </div>
            );
        }
        if (!['Available', 'Preparing'].includes(this.state.unitStatus)) {
            return (
                <div className="row">
                    <div className="col-md-12">
                        <p>Leider ist die Ladesäule nicht frei.</p>
                    </div>
                </div>
            );
        }
        return (
            <div className="row">
                <div className="col-md-12">
                    {false &&
                        <p>
                            <label htmlFor="meter-details">
                                <input type="checkbox" id="meter-details" name="meter-details"/>
                                {' '}Nerd-Modus: mehr Daten!
                            </label>
                        </p>
                    }
                    {this.state.unitStatus.status === 'Preparing' &&
                        <p>Sie haben das Fahrzeug bereits angesteckt.</p>
                    }
                    <p>
                        <button className="btn btn-block btn-primary" onClick={this.chargeStart.bind(this)}>
                            <a href={`/terms-and-conditions/${this.state.unit['unit-group'].operator_id}`} target="_blank">AGB</a>
                            {this.state.authType === 'account' && <span> und SEPA-Mandat</span>}
                            {' '}bestätigen und Ladevorgang
                            {this.state.authType !== 'free' &&
                                <span> kostenpflichtig</span>
                            }
                            {' '}starten
                        </button>
                    </p>
                </div>
            </div>
        )
    }

    renderChargeStarting () {
        if (this.state.appStatus !== 'starting')
            return;
        return(
            <div className="row">
                <div className="col-md-12">
                    <h3>Ladevorgang startet!</h3>
                    <p>
                        <i className="fa fa-spinner fa-spin fa-fw"></i>
                        {this.state.unitStatus.status === 'Available' &&
                            <span> Warte auf Ladesäule ... </span>
                        }
                        {this.state.unitStatus.status === 'Preparing' &&
                            <span> Wartet auf Stecker ... </span>
                        }
                    </p>
                    <p>Bitte folgen Sie den Anweisungen auf dem Säulen-Display.</p>
                </div>
            </div>
        )
    }

    renderChargeCharging() {
        if (this.state.appStatus !== 'charging')
            return;
        return(
            <div className="row">
                <div className="col-md-12">
                    <p><i className="fa fa-spinner fa-spin fa-fw"></i> Ladevorgang läuft!</p>
                    <button className="btn btn-block btn-primary" onClick={this.chargeStop.bind(this)}>
                        Ladevorgang beenden
                    </button>
                </div>
            </div>
        )
    }

    renderChargeStopping () {
        if (this.state.appStatus !== 'stopping')
            return;
        return(
            <div className="row">
                <div className="col-md-12">
                    <p><i className="fa fa-spinner fa-spin fa-fw"></i> Ladevorgang wird beendet ...</p>
                </div>
            </div>
        )
    }

    renderChargeComplete () {
        if (this.state.appStatus !== 'completed')
            return;
        return(
            <div className="row">
                <div className="col-md-12">
                    <h3>Ladevorgang abgeschlossen</h3>
                    {(this.state.unit.tech_backend === 'tcc' && this.state.unitMeterStop && this.state.unitChargeStop) &&
                    <p>
                        Sie haben {formatEnergy(this.state.unitMeterStop - this.state.unitMeterStart)}{' '}
                        in {formatDurationByTimestamp(this.state.unitChargeStart, this.state.unitChargeStop)} geladen.
                    </p>
                    }
                    {(this.state.unit.tech_backend === 'tcc' && (!this.state.unitMeterStop || !this.state.unitChargeStop)) &&
                    <p>... warte auf Messwerte ...</p>
                    }
                </div>
            </div>
        )
    }

    renderChargeTimeout() {
        if (this.state.appStatus !== 'timeout')
            return;
        return(
            <div className="row">
                <div className="col-md-12">
                    <p><i className="fa fa-exclamation"></i> Ladevorgang konnte nicht gestartet werden. Entweder haben
                    Sie das Kabel nichtzeitig gesteckt oder es gab einen Fehler bei der Kommunikation zwischen Fahrzeug
                    und Säule. Es wurde kein Geld abgebucht. Bitte versuchen Sie es erneut.</p>
                    <button className="btn btn-block btn-primary" onClick={() => location.reload()}>neu starten</button>
                </div>
            </div>
        )
    }
}
