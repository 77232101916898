import {sha256} from "../Common";

export default class Register {
    constructor() {
        if ($('#register-girocard-form, #account-form').length) {
            $('#validation_type').change(() => this.updateHidden());
            this.updateHidden(true);
        }
        if (document.getElementById('form-register-token')) {
            sha256(antispamToken).then((result => document.getElementById('token').value = result));
        }
    };

    updateHidden(init) {
        let validation_type = $('#validation_type').val();
        if (validation_type === 'CARDREADER') {
            if (!init)
                $('#register-row-girocard-auth').slideDown();
            $('#register-row-girocard-photo').slideUp();
        }
        else if (validation_type === 'PHOTO') {
            if (!init)
                $('#register-row-girocard-photo').slideDown();
            $('#register-row-girocard-auth').slideUp();
        }
        else if (!init) {
            $('#register-row-girocard-auth').slideUp();
            $('#register-row-girocard-photo').slideUp();
        }
    }
}
