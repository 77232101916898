import React from "react";
import moment from 'moment';
import SearchTable from './SearchTable'
import { daterangepicker_ranges, daterangepicker_locale} from "../Constants";
import { formatDaterange, formatDuration, formatPrice } from "../Format";

export default class SearchTableInvoiceTransaction extends SearchTable {
    params = {
        sort_field: 'begin',
        sort_order: 'asc',
        daterange: moment().subtract(1, 'year').format('DD.MM.YYYY') + ' - ' + moment().format('DD.MM.YYYY'),
        page: 1
    };
    apiUrl = '/api/invoice/transactions';
    formId = 'invoice-transaction-search-form';
    varPrefix = 'searchTableInvoiceTransaction';
    loadParamsRegExp = [
        /\/invoice\/(.*)/g
    ];

    sortDef = [
        { key: 'begin', name: 'Datum & Uhrzeit' },
        { key: 'value_total', name: 'Betrag' }
    ];

    colDef = [
        { sortField: 'created', text: 'Datum & Uhrzeit' },
        { sortField: 'uid', text: 'SEPA-ID'},
        { sortField: 'value_total', text: 'Betrag'},
        { text: 'Aktionen' }
    ];

    init() {
        super.init();
        this.params.invoice_id = document.getElementById('invoice-transaction-search-results').getAttribute('data-invoice-id');
        $('#daterange').daterangepicker({
            startDate: moment().subtract(1, 'year'),
            endDate: moment(),
            ranges: daterangepicker_ranges,
            locale: daterangepicker_locale
        }, (start, end, label) => this.daterangepickerSubmit(start, end, label));
    }

    renderTableRow(row) {
        return (
            <tr key={`charge-${row.id}`}>
                {this.renderTableCellDatetime(row)}
                {this.renderTableCellUid(row)}
                {this.renderTableCellValue(row)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }

    renderTableCellDatetime(row) {
        return(
            <td>
                {formatDaterange(row.begin, row.end)}
            </td>
        )
    }

    renderTableCellUid(row) {
        return(
            <td>
                {row.uid}
            </td>
        )
    }

    renderTableCellValue(row) {
        return(
            <td>
                {formatPrice(row.value_total)}
            </td>
        )
    }

    renderTableCellActions(row) {
        return(
            <td>
                {this.renderActionLink(`/charge/${row.id}/show`, 'info', true)}
            </td>
        )
    }
}