export default class Common {
    constructor() {
        window.onunload = function(){};
        this.storageAvailable = this.getStorageAvailable();
        this.setLastUrl();
        $('.btn-icon').tooltip()
    }

    setLastUrl() {
        if (!this.storageAvailable)
            return;
        this.lastUrl = localStorage.getItem('lastUrl');
        localStorage.setItem('lastUrl', window.location.pathname);
    }

    getStorageAvailable() {
        try {
            let x = '__storage_test__';
            localStorage.setItem(x, x);
            localStorage.removeItem(x);
            return true;
        }
        catch(e) {
            return e instanceof DOMException && (
                // everything except Firefox
                e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
                // acknowledge QuotaExceededError only if there's something already stored
                storage.length !== 0;
        }
    }
}

export const getURLParams = () => {
    let search = window.location.search;
    const hashes = search.slice(search.indexOf("?") + 1).split("&");
    return hashes.reduce((params, hash) => {
        const split = hash.indexOf("=");

        if (split < 0) {
            return Object.assign(params, {
                [hash]: null
            });
        }

        const key = hash.slice(0, split);
        const val = hash.slice(split + 1);

        return Object.assign(params, {[key]: decodeURIComponent(val)});
    }, {});
}

export const makeId = (length) => {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const sha256 = async (value) => {
    let cryptoObj = window.crypto || window.msCrypto;
    if (!cryptoObj || !cryptoObj.subtle) {
        return { then: function (resolver) { resolver(''); } };
    }
    let result = cryptoObj.subtle.digest("SHA-256", textEncode(value));
    if (result.then) {
        return result.then((hash) => {
            return hex(hash);
        });
    }
    if (result.result) {
        return {
            then: function (resolver) {
                resolver(hex(result.result));
            }
        }
    }
    return { then: function (resolver) { resolver(''); } };
}

export const hex = (buffer) => {
    return Array.prototype.map.call(new Uint8Array(buffer), x => ('00' + x.toString(16)).slice(-2)).join('');
}

const textEncode = (str) => {
    if (window.TextEncoder) {
        return new TextEncoder('utf-8').encode(str);
    }
    let utf8 = unescape(encodeURIComponent(str));
    let result = new Uint8Array(utf8.length);
    for (let i = 0; i < utf8.length; i++) {
        result[i] = utf8.charCodeAt(i);
    }
    return result;
}