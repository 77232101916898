export default class AccountManagement {
    constructor() {
        $('#validation_type').change(() => this.updateHidden());
        this.updateHidden();
    };

    updateHidden () {
        let validation_type = $('#validation_type').val();
        let auth_box = $('#account-validate-auth');
        let photo_box = $('#account-validate-photo');
        let person_box = $('#account-validate-person');
        let later_box = $('#account-validate-later');
        if (['auth', 'photo'].includes(validation_type)) {
            $('#submit').val('validieren');
        }
        else {
            $('#submit').val('weiter');
        }
        if (validation_type === 'CARDREADER') {
            auth_box.slideDown();
            photo_box.slideUp();
            person_box.slideUp();
            later_box.slideUp();
        }
        else if (validation_type === 'PHOTO') {
            auth_box.slideUp();
            photo_box.slideDown();
            person_box.slideUp();
            later_box.slideUp();
        }
        else if (validation_type === 'TRUSTED_PERSON') {
            auth_box.slideUp();
            photo_box.slideUp();
            person_box.slideDown();
            later_box.slideUp();
        }
        else if (validation_type === 'LATER') {
            auth_box.slideUp();
            photo_box.slideUp();
            person_box.slideUp();
            later_box.slideDown();
        }
    };
};