import {Decimal} from 'decimal.js';
import React from "react";
import ReactDOM from "react-dom";
import {formatPower, formatPrice, formatDuration} from "../Format";

export default class UnitGroupMap {
    plugs = {
        'ccs': 'CCS-Schnelladung',
        'chademo': 'CHAdeMO-Schnelladung',
        'typ2': 'Typ2 Buchse',
        'typ2-wired': 'Typ2 mit Kabel',
        'typ1': 'Typ1 Buchse',
        'typ1-wired': 'Typ1 mit Kabel',
        'schuko': 'SchuKo Buchse'
    };

    constructor() {
        import(/* webpackChunkName: "mapbox-gl" */ 'mapbox-gl').then(mapboxgl => {
            mapboxgl = mapboxgl.default;
            mapboxgl.accessToken = config.mapbox_token;

            this.map = new mapboxgl.Map({
                container: 'unit-group-map',
                style: 'mapbox://styles/mapbox/dark-v9',
                center: [10.448, 51.116],
                zoom: 5.5
            });
            this.map.addControl(new mapboxgl.NavigationControl(), 'top-left');

            var deref_map = new $.Deferred();
            var deref_data = new $.Deferred();

            $.when(deref_map, deref_data).done((mapready, data) => this.initMapData(mapready, data));
            this.map.on('load', function (data) {
                deref_map.resolve(data);
            });
            let geo_params = {};
            $.post(config.api_url + '/api/unit-groups/geojson', geo_params, function (data) {
                deref_data.resolve(data);
            });
        })
    };


    initMapData(mapready, data) {
        this.map.addSource('data-source', {
            type: 'geojson',
            data: data
        });
        this.map.addLayer({
            id: 'data-unit-groups',
            type: 'circle',
            source: 'data-source',
            paint: {
                'circle-radius': 6,
                'circle-color': '#5cb85c',
                'circle-stroke-width': 1,
                'circle-stroke-color': '#89f789'
            }
        });
        this.map.on('mouseenter', 'data-unit-groups', () => {
            this.map.getCanvasContainer().style.cursor = 'pointer';
        });
        this.map.on('mouseleave', 'data-unit-groups',() => {
            this.map.getCanvasContainer().style.cursor = '';
        });
        this.map.on('click', 'data-unit-groups', (e) => {
            this.showMapOverlay(e);
        });
    };


    showMapOverlay(e) {
        let data = e.features[0].properties;
        data.unit = JSON.parse(data.unit);
        ReactDOM.render(this.renderOverlayContent(data), document.getElementById('unit-group-map-overlay'));
        $('#unit-group-map-overlay').fadeIn();
    };

    renderOverlayContent(data) {
        let same_price = true;
        let items = [];
        let units = [
            {
                fee_base: Decimal((data.unit[0].fee_base) ? data.unit[0].fee_base: 0),
                fee_energy: Decimal((data.unit[0].fee_energy) ? data.unit[0].fee_energy : 0),
                fee_time: Decimal((data.unit[0].fee_time) ? data.unit[0].fee_time: 0),
                plug: data.unit[0].plug,
                free_time: data.unit[0].free_time,
                power: Decimal((data.unit[0].power) ? data.unit[0].power : 0),
                tech_backend: data.unit[0].tech_backend,
                uids: [data.unit[0].uid],
                remote_start: data.unit[0].remote_start,
                count: 1
            }
        ];

        for (let i = 1; i < data.unit.length; i++) {
            let found = false;
            for (let j = 0; j < units.length; j++) {
                if (
                    !units[j].fee_base.equals(Decimal((data.unit[i].fee_base) ? data.unit[i].fee_base : 0)) ||
                    !units[j].fee_energy.equals(Decimal((data.unit[i].fee_energy) ? data.unit[i].fee_energy : 0)) ||
                    !units[j].fee_time.equals(Decimal((data.unit[i].fee_time) ? data.unit[i].fee_time : 0))
                ) {
                    same_price = false;
                }
                if (
                    units[j].fee_base.equals(Decimal((data.unit[i].fee_base) ? data.unit[i].fee_base : 0)) &&
                    units[j].fee_energy.equals(Decimal((data.unit[i].fee_energy) ? data.unit[i].fee_energy : 0)) &&
                    units[j].fee_time.equals(Decimal((data.unit[i].fee_time) ? data.unit[i].fee_time : 0)) &&
                    units[j].power.equals(Decimal((data.unit[i].power) ? data.unit[i].power : 0)) &&
                    units[j].plug === data.unit[i].plug &&
                    units[j].tech_backend === data.unit[i].tech_backend &&
                    !units[j].remote_start
                ) {
                    units[j].count++;
                    units[j].uids.push(data.unit[i].uid);
                    found = true;
                }
            }
            if (!found) {
                units.push({
                    fee_base: Decimal((data.unit[i].fee_base) ? data.unit[i].fee_base: 0),
                    fee_energy: Decimal((data.unit[i].fee_energy) ? data.unit[i].fee_energy : 0),
                    fee_time: Decimal((data.unit[i].fee_time) ? data.unit[i].fee_time: 0),
                    plug: data.unit[i].plug,
                    power: Decimal((data.unit[i].power) ? data.unit[i].power : 0),
                    tech_backend: data.unit[i].tech_backend,
                    uids: [data.unit[i].uid],
                    remote_start: data.unit[i].remote_start,
                    count: 1
                })
            }
        }
        if (same_price) {
            items = items.concat(this.renderPrices(units[0]));
        }
        for (let i = 0; i < units.length; i++) {
            items.push(
                <h5>
                    {units[i].count > 1 &&
                    units[i].count + ' x '
                    }
                    {this.plugs[units[i].plug]}
                </h5>
            );
            console.log(units[i]);
            items.push(
                <p>
                    {formatPower(units[i].power)} Ladeleistung<br/>
                    {units[i].remote_start &&
                        <span>Ladepunkt {units[i].uids[0]}</span>
                    }
                    {units[i].remote_start &&
                        <a className="btn btn-block" href={`/go/${units[i].uids[0]}`}>jetzt laden</a>
                    }
                </p>
            );
            if (same_price)
                continue;
            items = items.concat(this.renderPrices(units[i]));
        }
        return (
            <div>
                <div onClick={this.closePopup.bind()} id="unit-group-map-overlay-close">
                    <i className="fa fa-times-circle-o" aria-hidden="true"></i>
                </div>
                <h4>{data.unit.length} Ladepunkt{(data.unit.length !== 1) ? 'e' : ''}</h4>
                <p>{data.address}, {data.postalcode} {data.locality}</p>
                <p>Betreiber: {data.operator_name} (<a href={`/terms-and-conditions/${data.operator_id}`}>AGB</a>)</p>
                <p className="flatParagraph">{items}</p>
                {data.description && [
                    <h4>Hinweis</h4>,
                    <p>{data.description}</p>
                ]}
            </div>
        )
    }

    renderPrices(unit) {
        let items = [];
        if (unit.fee_base.equals(0) && unit.fee_energy.equals(0) && unit.fee_time.equals(0)) {
            items.push(
                <p>kostenlose Nutzung</p>
            );
            return items;
        }
        if (!unit.fee_base.equals(0)) {
            items.push(
                <p>{formatPrice(unit.fee_base)} pro Ladestart</p>
            )
        }
        if (!unit.fee_energy.equals(0)) {
            items.push(
                <p>{formatPrice(unit.fee_energy)} pro kWh</p>
            )
        }
        if (!unit.fee_time.equals(0)) {
            items.push(
                <p>
                    {formatPrice(unit.fee_time)} pro Stunde
                    {!!unit.free_time && <span>ab {formatDuration(unit.fee_time)} Stunden</span>}
                </p>
            )
        }
        return items;
    }

    closePopup() {
        $('#unit-group-map-overlay').fadeOut();
    }
};
