import React from "react";

const { Component } = React;

export default class UnitChargeFree extends Component {
    params = {};
    state = {
        appStatus: 'booting'
    };

    componentDidMount() {
        this.params.csrf_token = config_unit.csrfToken;
        this.params.unit = config_unit.uid;
        if (this.props.authData) {
            this.setState({
                appStatus: 'selected',
                accountSelected: true,
                auth: this.props.authData,
                accountDescr: this.props.accountDescr
            });
            return;
        }
        let params = Object.assign({}, this.params);
        $.post('/api/unit/charge/account', params)
            .then(data => {
                if (data.data.type === 'AccountSelection') {
                    this.authorizeRequest();
                }
                if (data.data.type === 'LoginRequired') {
                    this.setState({
                        appStatus: 'login-or-register'
                    })
                }
            });
    }

    selectLoginOrRegister(selected) {
        this.setState({
            appStatus: selected
        });
    }

    loginFormSubmit(evt) {
        evt.preventDefault();
        if (!['login', 'login-error'].includes(this.state.appStatus))
            return;
        let params = Object.assign({}, this.params, {
            uid: this.props.unit.uid,
            email: document.getElementById('email').value,
            password: document.getElementById('password').value
        });
        $.post('/api/unit/charge/free/login', params)
            .then(data => {
                if (data.status) {
                    this.setState({
                        appStatus: 'login-error'
                    });
                    return;
                }
                this.authorizeRequest();
            });
    }

    registerFormSubmit(evt) {
        evt.preventDefault();
        if (!['register', 'register-error'].includes(this.state.appStatus))
            return;
        if (!document.getElementById('tos').checked) {
            this.setState({
                appStatus: 'register-error'
            });
            return;
        }
        let params = Object.assign({}, this.params, {
            uid: this.props.unit.uid,
            email: document.getElementById('email').value,
            tos: 'y'
        });

        $.post('/api/unit/charge/free/register', params)
            .then(data => {
                if (data.status) {
                    this.setState({
                        appStatus: 'register-error'
                    });
                    return;
                }
                this.authorizeRequest();
            });
    }

    authorizeRequest() {
        let params = Object.assign({}, this.params, {
            account: this.state.account,
            unit: this.props.unit.uid,
            ruid: this.props.requestUid,
            backend: this.props.unit.tech_backend
        });
        $.post('/api/unit/charge/free/auth', params)
            .then((data) => {
                this.props.setAuthResult(data.data.auth, 'Kostenloser Ladevorgang');
                this.setState({
                    appStatus: 'selected',
                    accountSelected: true,
                    auth: data.data.auth
                })
            });
    }

    render() {
        return (
            <div>
                {this.renderAccountLoginOrRegister()}
                {this.renderAccoutLogin()}
                {this.renderAccoutRegister()}
                {this.renderChargeAuthAccountData()}
            </div>
        )
    }

    renderAccountLoginOrRegister() {
        if (!['login-or-register', 'login', 'login-error', 'register', 'register-error'].includes(this.state.appStatus))
            return;
        return([
            <div className="row row-form" key="login-select">
                <div className="col-md-12">
                    <p><i className="fa fa-battery-half" aria-hidden="true"></i> kostenloser Ladevorgang</p>
                </div>
            </div>,
            <div className="row row-form" key="register-select">
                <div className="col-md-6" style={{marginBottom: '10px'}}>
                    <button
                        className={`btn btn-block ${['register', 'register-error', 'login-or-register'].includes(this.state.appStatus) ? 'btn-primary' : 'btn-light'}`}
                        onClick={this.selectLoginOrRegister.bind(this, 'register')}
                    >
                        Neu-Registrierung
                    </button>
                </div>
                <div className="col-md-6">
                    <button
                        className={`btn btn-block ${['login', 'login-error', 'login-or-register'].includes(this.state.appStatus) ? 'btn-primary' : 'btn-light'}`}
                        onClick={this.selectLoginOrRegister.bind(this, 'login')}
                    >
                        Kunden-Login
                    </button>
                </div>
            </div>
        ])
    }

    renderAccoutLogin() {
        if (!['login', 'login-error'].includes(this.state.appStatus))
            return;
        return(
            <form onSubmit={this.loginFormSubmit.bind(this)}>
                <div className="row">
                    <div className="col-md-12">
                        <p>Mit bereits registrierter E-Mail-Adresse Ihres Giro-e Accounts anmelden.</p>
                    </div>
                </div>
                <div className="row">
                    <div className={`col-md-4${(this.state.appStatus === 'login-error') ? ' error' : ''}`}>
                        <label htmlFor="email">E-Mail</label>
                        <input type="text" name="email" id="email" className="form-control" />
                    </div>
                    <div className={`col-md-4${(this.state.appStatus === 'login-error') ? ' error' : ''}`}>
                        <label htmlFor="password">Passwort</label>
                        <input type="password" name="password" id="password" className="form-control" />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="password">&nbsp;</label>
                        <input type="submit" name="password" id="password" className="form-control" value="login" />
                    </div>
                </div>
            </form>
        );
    }

    renderAccoutRegister() {
        if (!['register', 'register-error'].includes(this.state.appStatus))
            return;
        return(
            <form onSubmit={this.registerFormSubmit.bind(this)}>
                <div className="row">
                    <div className="col-md-12">
                        <p>Nur mit gültiger E-Mail-Adresse zur Registrierung in Giro-e können Sie Ladevorgänge stoppen.</p>
                    </div>
                </div>
                <div className="row">
                    <div className={`col-md-4${(this.state.appStatus === 'register-error') ? ' error' : ''}`}>
                        <label htmlFor="email">E-Mail</label>
                        <input type="text" name="email" id="email" className="form-control" />
                    </div>
                    <div className={`col-md-4${(this.state.appStatus === 'register-error') ? ' error' : ''}`}>
                        <label htmlFor="tos">
                            {' '}<br/>
                            <input type="checkbox" name="tos" id="tos" />
                            Ich habe die AGB gelesen und verstanden:
                        </label>
                        <a href={`/terms-and-conditions/${this.props.unit.operator_id}`}>(link)</a>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="password">&nbsp;</label>
                        <input type="submit" name="password" id="password" className="form-control" value="registrieren" />
                    </div>
                </div>
            </form>
        );
    }

    renderChargeAuthAccountData() {
        if (this.state.appStatus !== 'selected')
            return;
        return(
            <div className="row">
                <div className="col-md-12">
                    <p><i className="fa fa-credit-card" aria-hidden="true"></i> Kostenloser Ladevorgang</p>
                </div>
            </div>
        )
    }
}