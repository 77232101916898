import React from "react";
import moment from 'moment';
import SearchTable from './SearchTable'
import { formatPrice } from "../Format";
import { daterangepicker_ranges, daterangepicker_locale } from "../Constants";

export default class SearchTableInvoice extends SearchTable {
    params = {
        sort_field: 'created',
        sort_order: 'desc',
        daterange: moment().subtract(1, 'month').format('DD.MM.YYYY') + ' - ' + moment().format('DD.MM.YYYY'),
        page: 1
    };
    apiUrl = '/api/invoices';
    formId = 'invoice-search-form';
    varPrefix = 'searchTableInvoice';
    loadParamsRegExp = [
        /\/invoice\/(.*)/g
    ];

    sortDef = [
        { key: 'created', name: 'Datum' },
        { key: 'amount', name: 'Betrag' }
    ];

    colDef = [
        { sortField: 'created', text: 'Datum' },
        { text: 'Verkäufer' },
        { text: 'Rechnungsnummer' },
        { sortField: 'amount', text: 'Betrag'},
        { text: 'Aktionen' }
    ];

    init() {
        super.init();
        $('#daterange').daterangepicker({
            startDate: moment().subtract(1, 'month'),
            endDate: moment(),
            ranges: daterangepicker_ranges,
            locale: daterangepicker_locale
        }, (start, end, label) => this.daterangepickerSubmit(start, end, label));
    }

    renderTableRow(row) {
        return (
            <tr key={`invoice-${row.id}`}>
                {this.renderTableCellCreated(row)}
                {this.renderTableCellOperator(row)}
                {this.renderTableCellInvoiceNumber(row)}
                {this.renderTableCellValue(row)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }
    renderTableCellOperator(row) {
        return(
            <td>
                {row.operator.name}
            </td>
        )
    }

    renderTableCellCreated(row) {
        return(
            <td>
                {this.formatDate(row.created)}
            </td>
        )
    }

    renderTableCellInvoiceNumber(row) {
        return(
            <td>
                {row.invoice_number}<br/>
                <small>SEPA-ID: {row.uid}</small>
            </td>
        )
    }

    renderTableCellValue(row) {
        return(
            <td>
                {formatPrice(row.value)}
            </td>
        )
    }

    renderTableCellActions(row) {
        return(
            <td>
                {this.renderActionLink(`/invoice/${row.id}/show`, 'info', true)}
            </td>
        )
    }

}